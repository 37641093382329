<template>
  <div>
      <b-row>
        <b-col cols="12" md="3">
          
        </b-col>
        <b-col cols="6" md="3">
          <div class="logosSuperiores">
            <b-form-file v-model="archivo" ref="file-input" class="subirExcel" accept="application/vnd.ms-excel, vnd.sealed.xls, text/csv" @change="onLoad"></b-form-file>
            <h5>Seleccionar</h5>
            <span>{{ archivo ? archivo.name : '' }}</span>
          </div>
        </b-col>
        <b-col cols="6" md="3">
          <div class="logosSuperiores">
            <button class="imagenSubir" :disabled="deshabilitarSubir" @click="modalConfirmarSubida"></button>
             <h5>Subir</h5>
          </div>
        </b-col>
        <b-col cols="12" md="3">
          
        </b-col>
      </b-row>
      <br /> <br />
      <b-row>
        <b-col>
          <b-table striped responsive small bordered :items="dataCompleta" :tbody-tr-class="rowClass"></b-table>
        </b-col>
      </b-row>
      
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import XLSX from 'xlsx'
// import axios from 'axios';
import moment from 'moment';
// import {RCDGETLICENCIABYNUMACTO, } from '../../constantes';
import { encontrarError } from '../../contantesErrores';

export default {
  name: "CargarArchivosRecicladores",
  data(){
    return { 
      archivo: [],
      tableData:[],
      tableRow: [],
      dataCompleta: [],
      datosASubir: [],
      interval: null,
      deshabilitarSubir:true,
      errorFatal: false
    }
  },
  async mounted(){
    try{
      await this.cargarMunicipiosCompletos()
      await this.cargarAsociaciones()
      console.log('asociaciones', this.asociaciones)
    }catch(e){
      encontrarError(this, e.data)
    }
  },
  computed:{
    ...mapState('recicladores', ['resultCreateReciclador','municipiosServicios', 'asociaciones', 'recicladorCargadoByIdentificacion']),

    mapearTipoIdentidad(tipo){
      tipo.toUpperCase().trim();

      let id = null
      if(tipo=="CC"){
        id = 1
      }
      if(tipo=="TI"){
        id = 2
      }
      if(tipo=="RC"){
        id = 3
      }
      if(tipo=="PASAPORTE"){
        id = 4
      }
      if(tipo=="CE"){
        id = 5
      }
      return id;
    },

    mapearCiudades(nombreCiudad){
      let id=-1
      let municipio = this.municipiosServicios.find(item=>{
        if(item.nombre.toUpperCase() == nombreCiudad.toUpperCase().trim()){
          return item
        }
      })
      console.log('municipio', municipio)
      if (municipio){
        id = municipio.id
      }
      return id
    },

    mapeartipoVehiculo(tipoVehiculo){
      tipoVehiculo.toUpperCase().trim()
      let id = null;
      if(tipoVehiculo=="Zorra Humana"){
        id=0
      }
      if(tipoVehiculo=="Zorra Caballo"){
        id=1
      }
      if(tipoVehiculo=="Moto remolque"){
        id=2
      }
      if(tipoVehiculo=="Motocarro"){
        id=3
      }
      if(tipoVehiculo=="Vehiculo de carga"){
        id=5
      }

      return id
      
    },

   
  },
  methods: {
    ...mapActions(['activarLoading']),
    ...mapActions('recicladores', [
        'cargarMunicipiosCompletos', 
        'cargarRecicladorByIdentificacion', 
        'cargarAsociaciones',
        'createReciclador'
        ]),
    
    explorarArchivo(f){
      this.borrarData()
      
      var reader = new FileReader()
          // vm = this;
      reader.onload =  (e) =>{
        var data = e.target.result,
            workbook = XLSX.read(data, { type: 'binary' }),
            sheetName = workbook.SheetNames[0],
            sheet = workbook.Sheets[sheetName],
            colTotal = XLSX.utils.sheet_to_json(sheet,{
              header:["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20",
              "21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40",
              "41","42","43","44","45","46","47","48","49","50","51","52","53"], 
              defval: ''});
              console.log('contotal', colTotal)
        this.activarLoading(false)
        this.crearDataCompleta(colTotal)
      };
      reader.readAsBinaryString(f);
    },

    onLoad(){
      this.activarLoading(true)
      this.interval = setInterval(this.verificarArchivo, 500)
    },

    verificarArchivo(){
      if(this.archivo!=null){
        this.explorarArchivo(this.archivo);
        clearInterval(this.interval)
      }
    },

    async crearDataCompleta(array){
      for(let i= 6; i<array.length; i++){
          let items = array[i]
          let linea = {resultado: null, ...this.mapearRegistros(items), error: null}
          if(linea.identificacion != 0 && linea.nombre_1 != '' ){
            // let existe = await this.verificarRegistro(linea.identificacion)
            // linea.Registro = existe
            this.dataCompleta.push(linea)
            this.datosASubir.push({...this.mapearRegistrosGuardar(items)})
          }
          if(i==array.length-1){
            if(!this.errorFatal){
              this.deshabilitarSubir = false;
            }
          }
        }
    },
    
    rowClass(item, type) {
      if (!item || type !== 'row') return
      return 'rowspace'
    },

    async verificarRegistro(identificacion){
        await this.cargarRecicladorByIdentificacion(identificacion)
        if(this.recicladorCargadoByIdentificacion){
          return 'existente'
        }else{
          return 'Nuevo'
        }
    },

    async guardarResgistrosBD(){
      this.activarLoading(true)
      console.log('datos Subir', this.datosASubir)
      let errores = 0;

      for(let i=0; i<this.datosASubir.length; i++){

        let linea = this.datosASubir[i]

        let item= {
          registro: linea
        }


        try{
            await this.createReciclador(item)
            if(this.resultCreateReciclador.data.status == 200){
              this.dataCompleta[i].resultado = "Creado"
            }
            console.log('errores', errores)
            if(i==this.datosASubir.length-1){
              this.activarLoading(false)
              // this.borrarData()
              if(errores>0){
                this.modalError()
              }else{
                this.modalOk()
              }
            }
        } catch (e) {
          this.activarLoading(false)
          // encontrarError(this, e.data);
          if(e.data.codigo == "23505"){
            this.dataCompleta[i].resultado = "Existente"
          }else{
            this.dataCompleta[i].resultado = "Error"
            this.dataCompleta[i].error = e.data.message
            errores++
          }
          console.log("error", e.data)

          if(i==this.datosASubir.length-1){
              this.activarLoading(false)
              // this.borrarData()
              if(errores>0){
                this.modalError()
              }else{
                this.modalOk()
              }
            }
        }

        
      }
    },

    modalConfirmarSubida(){
      if(this.errorFatal){
        this.$bvModal.msgBoxConfirm('No se ha corregido el archivo actual', {
          title: 'Por favor confirme',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continuar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(() => {
          })
          .catch(err => {
            console.log(err)
          })
      }else{

        this.$bvModal.msgBoxConfirm('Se van a cargar los registros del archivo al sistema, ¿Desea continuar?.', {
          title: 'Por favor confirme',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Continuar',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value){
              this.guardarResgistrosBD()
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    modalOk(){
      this.$bvModal.msgBoxOk('Los registros fueron procesados correctamente.', {
          title: 'Confirmación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            console.log(value)
          })
          .catch(err => {
            console.log(err)
          })
    },

    modalError(){
      this.$bvModal.msgBoxOk('Se presentaron errores durante la carga, verifique la estructura del archivo excel.', {
          title: 'Error',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            console.log(value)
          })
          .catch(err => {
            console.log(err)
          })
    },
    modalErrorFatal(){
      this.$bvModal.msgBoxOk(`Se presentaron errores durante la carga de la información debido a inconsistencias en la 
        estructura del archivo excel. Corrija la estructura del archivo e intente nuevamente.`, {
          title: 'Error',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
          .then(value => {
            console.log(value)
          })
          .catch(err => {
            console.log(err)
          })
    },

    borrarData(){
        this.archivo =  []
        this.tableData = []
        this.tableRow =  []
        this.dataCompleta =  [],
        this.datosASubir=[],
        this.deshabilitarSubir = true
        this.errorFatal = false
    },

    mapearRegistros(linea){
      let item={
        id_asociacion: linea['11'],
        nombre_1: linea['3'],
        nombre_2: linea['4'],
        apellido_1: linea['5'],
        apellido_2: linea['6'],
        sexo: null,
        pobla_rom: null,
        tipo_iden: linea['7'],
        identificacion: linea['8']!=''?linea['8']:0,
        id_ciudad_expedicion:linea['9'],
        cabeza_hogar: linea['12'],
        id_ciudad_nacim: linea['14'],
        fecha_nacim: moment(this.excelDateToJSDate(linea['15']), 'MM-DD-YYYY').format('YYYY-MM-DD'),
        edad: null,
        direccion: linea['19'],
        id_ciudad_res: linea['18'],
        estrato: linea['20'],
        tel_fijo: linea['21'],
        tel_celular: linea['22'],
        lee: linea['23'],
        escribe: linea['24'],
        nivel_educativo:linea['25'],
        licencia_cond: linea['26'],
        categoria: linea['27'],
        tipo_vehiculo: linea['28'],
        seguridad_social: linea['29'],
        pension: linea['31'],
        arl: linea['33'],
        tipo_vivienda: linea['34'],
        vivienda_es: linea['35'],
        meses_actividad: linea['36'],
        actividad_anterior: linea['37'],
        dias_semana: linea['38'],
        horas_semana:linea['39'],
        actividad_alterna: linea['40'],
        lugar_rec_material: linea['41'],
        vehiculo_utilizado: linea['42'],
        titularidad_vehiculo: linea['43'],
        rec_papel: linea['44'],
        rec_carton: linea['45'],
        rec_plastico: linea['46'],
        rec_aluminio: linea['47'],
        rec_vidrio: linea['48'],
        rec_otro: linea['49'],
        otro_cual: null,
        sitio_venta: linea['50'],
        ubicacion: linea['51'],
        ingresos_semana: linea['52'],
      }
      return item
    },

    mapearRegistrosGuardar(linea){
      try{
        let item={
          fecha_cargue: moment(),
          id_asociacion: this.traerIdAsociacionByNombre(linea['11']),
          foto: null,
          nombre_1: linea['3'],
          nombre_2: linea['4'],
          apellido_1: linea['5'],
          apellido_2: linea['6'],
          sexo: null,
          pobla_rom: null,
          tipo_iden: this.traerIdTipoDocumento(linea['7']),
          identificacion: linea['8']!= ''?linea['8']:0,
          id_ciudad_expedicion: this.traerIdMunicipioByNombre(linea['9']),
          cabeza_hogar: linea['12']?linea['12'].toUpperCase()=="SI"?1:0:0,
          id_ciudad_nacim: this.traerIdMunicipioByNombre(linea['14']),
          fecha_nacim: moment(this.excelDateToJSDate(linea['15']), 'MM-DD-YYYY').format('YYYY-MM-DD'),
          edad: null,
          direccion: linea['19'],
          id_ciudad_res: this.traerIdMunicipioByNombre(linea['18']),
          estrato: !isNaN(parseInt(linea['20']))?linea['20']:0,
          tel_fijo: linea['21'],
          tel_celular: linea['22'],
          lee: linea['23']?linea['23'].toUpperCase() == 'SI'?1:0:0,
          escribe: linea['24']?linea['24'].toUpperCase() == 'SI'?1:0:0,
          nivel_educativo: this.traerIdNivelEductivo(linea['25']),
          licencia_cond: linea['26']?linea['26'].toUpperCase() == 'SI'?1:0:0,
          categoria: linea['27']!=''?linea['27'].toString().substr(0,9):null,
          tipo_vehiculo: linea['28']!=''?linea['28']:null,
          seguridad_social: linea['29']!=''?linea['29']:null,
          pension: linea['31']!=''?linea['31']:null,
          arl: linea['33']!=''?linea['33']:null,
          tipo_vivienda: linea['34']!=''?linea['34']:null,
          vivienda_es: linea['35']!=''?linea['35']:null,
          meses_actividad: !isNaN(parseInt(linea['36']))?linea['36']:0,
          actividad_anterior: linea['37']!=''?linea['37']:null,
          dias_semana: !isNaN(parseInt(linea['38']))?linea['38']:0,
          horas_semana: !isNaN(parseInt(linea['39']))?linea['39']:0,
          actividad_alterna: linea['40']!=''?linea['40']:null,
          lugar_rec_material: linea['41']!=''?linea['41']:null,
          vehiculo_utilizado: linea['42']!=''?linea['42']:null,
          titularidad_vehiculo: linea['43']!=''?linea['43']:null,
          rec_papel: linea['44']?linea['44'].toUpperCase() == 'X'?1:0:0,
          rec_carton: linea['45']?linea['45'].toUpperCase() == 'X'?1:0:0,
          rec_plastico: linea['46']?linea['46'].toUpperCase() == 'X'?1:0:0,
          rec_aluminio: linea['47']?linea['47'].toUpperCase() == 'X'?1:0:0,
          rec_vidrio: linea['48']?linea['48'].toUpperCase() == 'X'?1:0:0,
          rec_otro: linea['49']?linea['49'].toUpperCase() == 'X'?1:0:0,
          otro_cual: null,
          sitio_venta: linea['50']!=''?linea['50']:null,
          ubicacion: linea['51']!=''?linea['51']:null,
          ingresos_semana: !isNaN(parseInt(linea['52']))?linea['52']:0,
        }
        return item
      }catch(e){
        console.log('error fatal', e)
        this.modalErrorFatal()
        this.errorFatal = true
        this.dataCompleta[this.dataCompleta.length-1].resultado = 'Error'
      }
    },

    traerIdAsociacionByNombre(nombre){
      let id=null
      this.asociaciones.find(item=>{
        if(item.nombre.toUpperCase().trim() == nombre.toUpperCase().trim() || 
          item.nombre_corto.toUpperCase().trim() == nombre.toUpperCase().trim()){
          id = item.id
        }
      })
      return id
    },

    traerIdTipoDocumento(tipoId){
      let tipo = tipoId.toUpperCase().trim();
      let tipoIden = 1
      if(tipo=="C.C" || tipo=="CC" || tipo=="C C" || tipo=="C.C."){
        tipoIden = 1
      }
      if(tipo=="T.I" || tipo=="TI" || tipo=="T I" || tipo=="T.I."){
        tipoIden = 2
      }
      if(tipo=="R.C" || tipo=="RC" || tipo=="R C" || tipo=="R.C."){
        tipoIden = 3
      }
      if(tipo=="PASAPORTE"){
        tipoIden = 4
      }
      if(tipo=="C.E" || tipo=="CE" || tipo=="C E" || tipo=="C.E."){
        tipoIden = 5
      }

      return tipoIden
    },
    
    traerIdNivelEductivo(tipoNiv){
      let tipo = tipoNiv.toUpperCase().trim()
      let tipoNivel = -1
      if(tipo=="" || tipo=="NINGUNO"){
        tipoNivel = 0
      }
      if(tipo=="PRIMARIA"){
        tipoNivel = 1
      }
      if(tipo=="SECUNDARIA"){
        tipoNivel = 2
      }
      if(tipo=="TECNICA" || tipo=="TECNOLOGICA" || tipo=="TECNICO" || tipo=="TECNOLOGICO"){
        tipoNivel = 3
      }
      if(tipo =="UNIVERSITARIA" || tipo =="UNIVERSITARIO"){
        tipoNivel = 4
      }
      if(tipo=="POSGRADO"){
        tipoNivel = 5
      }

      return tipoNivel
    },

    traerIdMunicipioByNombre(nombre){
      let id=0
      this.municipiosServicios.find(item=>{
        if(item.nombre.toUpperCase().trim() == nombre.toUpperCase().trim()){
          id = item.id
        }
      })
      return id
    },

    excelDateToJSDate(excel_date, time = false) {
      let day_time = excel_date % 1
      let meridiem = "AMPM"
      let hour = Math.floor(day_time * 24)
      let minute = Math.floor(Math.abs(day_time * 24 * 60) % 60)
      let second = Math.floor(Math.abs(day_time * 24 * 60 * 60) % 60)
      hour >= 12 ? meridiem = meridiem.slice(2, 4) : meridiem = meridiem.slice(0, 2)
      hour > 12 ? hour = hour - 12 : hour = hour - 0
      hour = hour < 10 ? "0" + hour : hour
      minute = minute < 10 ? "0" + minute : minute
      second = second < 10 ? "0" + second : second
      let daytime = "" + hour + ":" + minute + ":" + second + " " + meridiem
      return time ? daytime : (new Date(0, 0, excel_date, 0, -new Date(0).getTimezoneOffset(), 0)).toLocaleDateString(navigator.language, {}) + " " + daytime
    }

    // traerNombreCiudadByID(id){
    //   let ciudad = null
    //   this.municipiosServicios.find(item=>{
    //     if(item.id==id){
    //       ciudad = item.nombre
    //       this.ciudades[item]
    //     }
    //   })
    //   console.log(ciudad)
    //   return ciudad
    // },
    
  }
}
</script>

<style scoped>

  .logosSuperiores{
    background: #fff;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px #555;
    text-align: center;
  }

  .logosSuperiores .imagenExaminar {
    background: url('../../assets/iconosApp.png');
    background-position: -13px -9px;
    height: 66px;
    width: 48px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .logosSuperiores button:disabled{
    opacity: 0.5;
  }
  
  .logosSuperiores .imagenSubir {
    background: url('../../assets/iconosApp.png');
    background-position: -71px -10px;
    border: none;
    margin: 34px 0;
    height: 70px;
    width: 102px;
    position: relative;
  }
  
</style>